import './IsLoading.css'

function IsLoading() {
  return (
    <div className='IsLoading'>
      <div className='IsLoading-spinner'></div>
    </div>
  );
}

export default IsLoading;